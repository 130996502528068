import {mapMutations} from "vuex";
export default {
  name: "solution",
  components:{
  },
  data(){
    return{
      showVideo: false,
      isMobile: false,
    }
  },
  created() {
  },
  computed:{

  },
  mounted() {
    this.isMobile = window.innerWidth < 960
  },
  methods:{
    ...mapMutations({
      changeCalendly: 'app/CHANGE_CALENDLY'
    })
  }
}
