import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
export default {
  name: "press",
  components:{
  },
  mixins: [validationMixin],
  data(){
    return{
      payload: {
        first_name: '',
        last_name: '',
        email: '',
        message: ''
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      first_name: {
        minLength: minLength(2),
        maxLength: maxLength(100),
        required,
      },
      last_name: {
        minLength: minLength(2),
        maxLength: maxLength(100),
        required,
      },
      email: {
        email,
        maxLength: maxLength(100),
        required,
      },
      message: {
        minLength: minLength(2),
        maxLength: maxLength(300),
        required,
      },
    }
  },
  created() {
  },
  computed:{
    ...mapGetters({
      loadingFeedback: 'app/loadingFeedback'
    }),
    firstNameError() {
      const error = []
      if (!this.$v.payload.first_name.$dirty) {
        return error
      }
      if (!this.$v.payload.first_name.required) {
        error.push('This field is required!')
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push('Min length is :count!'.replace(':count', 2))
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push('Max length is :count!'.replace(':count', 100))
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push('This field is required!')
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push('Min length is :count!'.replace(':count', 2))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push('Max length is :count!'.replace(':count', 100))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    messageError() {
      const error = []
      if (!this.$v.payload.message.$dirty) {
        return error
      }
      if (!this.$v.payload.message.required) {
        error.push('This field is required!')
      }
      if (!this.$v.payload.message.minLength) {
        error.push('Min length is :count!'.replace(':count', 2))
      }
      if (!this.$v.payload.message.maxLength) {
        error.push('Max length is :count!'.replace(':count', 300))
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push('This field is required!')
      }
      if (!this.$v.payload.email.email) {
        error.push('Enter correct email!')
      }
      if (!this.$v.payload.email.maxLength) {
        error.push('Max length is :count!'.replace(':count', 100))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
  },
  methods:{
    ...mapActions({
      sendFeedback: 'app/SEND_FEEDBACK'
    }),
    submitForm(){
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.sendFeedback(this.payload).then(()=>{
          this.$toasted.success('Successfully sent!')
          this.payload.email = ''
          this.payload.first_name = ''
          this.payload.last_name = ''
          this.payload.message = ''
          this.$v.$reset()
        }).catch(error => {
          this.$toasted.error(error.data.message)
        })
      }
    }
  }
}
