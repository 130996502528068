import {mapMutations} from "vuex";
export default {
  name: "improve",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },
  methods:{
    ...mapMutations({
    })
  }
}
