import {mapGetters, mapMutations} from "vuex";

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import solution from "@/modules/home/section/solution/index.vue";
import advantages from "@/modules/home/section/advantages/index.vue";
import whyUs from "@/modules/home/section/why-us/index.vue";
import improve from "@/modules/home/section/improve/index.vue";
import press from "@/modules/home/section/press/index.vue";
import accelerators from "@/modules/home/section/accelerators/index.vue";
import calendly from "@/modules/home/section/calendly/index.vue";

export default {
    name: "home",
    components: {
        promoSection,
        solution,
        advantages,
        whyUs,
        improve,
        press,
        accelerators,
        calendly,
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {
        ...mapGetters({
            showCalendly: 'app/showCalendly'
        })
    },

    methods: {
        ...mapMutations({
        })
    }
}
