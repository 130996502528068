import {mapMutations} from "vuex";
import Vue from 'vue';
import VueCalendly from 'vue-calendly';

Vue.use(VueCalendly);
export default {
  name: "calendly",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },
  methods:{
    ...mapMutations({
      changeCalendly: 'app/CHANGE_CALENDLY'
    }),
  }
}