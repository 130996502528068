import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength, required, email} from "vuelidate/lib/validators";

export default {
  name: "improve",
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: ''
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        email,
        maxLength: maxLength(100),
        required,
      },
    }
  },
  created() {
  },
  computed: {
    ...mapGetters({
      loadingEmail: 'app/loadingEmail'
    }),
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push('This field is required!')
      }
      if (!this.$v.payload.email.email) {
        error.push('Enter correct email!')
      }
      if (!this.$v.payload.email.maxLength) {
        error.push('Max length is :count!'.replace(':count', 100))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },

  },
  methods: {
    ...mapMutations({
      changeCalendly: 'app/CHANGE_CALENDLY'
    }),
    ...mapActions({
      sendEmail: 'app/SEND_EMAIL'
    }),
    submitForm(){
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.sendEmail(this.payload).then(()=>{
          this.$toasted.success('Successfully subscribed!')
          this.payload.email = ''
          this.$v.$reset()
        }).catch(error => {
          this.$toasted.error(error.data.message)
        })
      }
    }
  }
}
